import { useCallback, useState } from "react";

export const useConfirmDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleResult = useCallback(
    (successCallback?: () => void) => (result: boolean) => {
      setIsOpen(false);

      if (result && successCallback) {
        successCallback();
      }
    },
    [],
  );

  const open = useCallback(() => setIsOpen(true), []);

  return {
    isOpen,
    handleResult,
    open,
  };
};
