import type { InvoiceListItemDto } from "Api/Api";
import { removeDiacritics } from "Utils/StringUtils";
import { appUrl } from "Utils/UrlUtils";

/**
 * https://qr-platba.cz/pro-vyvojare/specifikace-formatu/
 * @param invoice
 * @param amount
 * @param currencyCode
 * @returns
 */
export function getInvoicePaymentDescriptor(
  invoice: InvoiceListItemDto,
  amount: number,
  currencyCode: string,
): string {
  return `SPD*1.0*ACC:${invoice.iban}*CC:${currencyCode}*RN:Moone.io*X-VS:${invoice.payerReference}*X-SS:${invoice.paymentReference}*AM:${amount}*PT:IP*MSG:Objednavka Moone`;
}

/**
 * https://qr-platba.cz/pro-vyvojare/specifikace-formatu/
 * @param iban
 * @param paymentReference
 * @param amount
 * @param currencyCode
 * @param message
 * @returns
 */
export function getPaymentDescriptor(
  iban: string,
  paymentReference: string,
  amount: number,
  currencyCode: string,
  message: string,
): string {
  const sanitizedMessage = removeDiacritics(message);
  return `SPD*1.0*ACC:${iban}*CC:${currencyCode}*RN:Moone.io*X-VS:${paymentReference}*AM:${amount}*PT:IP*MSG:${sanitizedMessage}`;
}

export const getPaymentLinkDescriptor = (publicID: string) => {
  const domain = window.location.origin;
  const path = appUrl("customer-payment-pay");

  return `${domain}${path}/${publicID}`;
};

export const isSandbox = (bank?: string) =>
  bank?.toLowerCase().endsWith("_sandbox");
