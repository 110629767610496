import { Stack } from "@mui/material";
import { BankSelectorComponent } from "Components/Banking/Auth/BankSelectorComponent";
import { useOnboardingContext } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import { BankTile } from "Components/Shared/BankTile";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { useBankingAuth } from "Hooks/useBankingAuth";
import type { BankEnvironment } from "Infrastructure/Api/Api";
import type { FC } from "react";

export const BankAccountStep: FC = () => {
  const { nextStep } = useOnboardingContext();

  const { isPending, authorize, selectedEnvironment } = useBankingAuth();

  const onBankSelected = (environment: BankEnvironment) => {
    if (!isPending) {
      authorize({ environment });
    }
  };

  return (
    <FullHeightForm>
      <Stack gap={4}>
        <BankSelectorComponent
          isActionPending={isPending}
          onBankSelected={onBankSelected}
          selectedEnvironment={selectedEnvironment}
        />
        <BankTile bank="Unknown" onAuthorize={() => nextStep({ bank: null })} />
      </Stack>
    </FullHeightForm>
  );
};
