import { Button, Box } from "@mui/material";
import { ArrowRightIcon } from "Components/Shared/Icons";

type Props = { onClick: () => void };

export const GetLicenseButton = ({ onClick }: Props) => (
  <Button
    onClick={onClick}
    variant="contained"
    size="large"
    sx={{
      py: 3,
      pl: 10,
      pr: 6,
      my: 4,
      fontSize: "1.25rem",
      alignSelf: "center",
      textTransform: "none",
      borderRadius: "20px",
      fontWeight: 300,
    }}
  >
    <Box display="flex" gap={2} alignItems="center">
      Získat licenci Huntera
      <Box sx={{ transform: "rotate(-90deg)" }}>
        <ArrowRightIcon height="12px" />
      </Box>
    </Box>
  </Button>
);
