import { Box, Grid2, List, ListItem, Stack, Typography } from "@mui/material";
import {
  DoneIcon,
  MoneyBoxIcon,
  ShoppingCartIcon,
  VerificationBadgeIcon,
} from "Components/Shared/Icons";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";

export const HunterRoleExplanation = () => (
  <Stack gap={3} sx={{ width: ["95vw", "70vw"] }}>
    <Typography variant="h2" mb={3}>
      Jak funguje role Huntera?
    </Typography>
    <Grid2 container spacing={4} sx={{ display: "flex" }}>
      <Grid2 size={{ xs: 12, md: 4 }}>
        <PrimaryPaper
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="h6">1. Získáš licenci</Typography>
            <Typography>
              Vybereš si úroveň licence, projdeš úvodním školením a získáš
              přístup k platformě.
            </Typography>
          </Box>
          <VerificationBadgeIcon width={50} height={50} />
        </PrimaryPaper>
      </Grid2>
      <Grid2 size={{ xs: 12, md: 4 }}>
        <PrimaryPaper
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="h6">2. Pomáháš obchodníkům</Typography>
            <Typography>
              Představíš jim výhody přímých plateb, pomůžeš se zapojením, předáš
              terminál či pokladnu.
            </Typography>
          </Box>
          <ShoppingCartIcon />
        </PrimaryPaper>
      </Grid2>
      <Grid2 size={{ xs: 12, md: 4 }}>
        <PrimaryPaper
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="h6">3. Buduješ pravidelný příjem</Typography>
            <Typography>
              Z každé uskutečněné transakce získáváš malou provizi – den za
              dnem, obchod po obchodu.
            </Typography>
          </Box>
          <MoneyBoxIcon />
        </PrimaryPaper>
      </Grid2>
    </Grid2>
    <PrimaryPaper>
      <Stack gap={5}>
        <Box>
          <Typography variant="h6">Příklad výdělku</Typography>• 50 aktivních
          obchodníků = cca 28 000 Kč měsíčně <br />• 100 obchodníků = až 112 000
          Kč měsíčně
        </Box>
        <Box>
          <Typography variant="h6">Co získáš jako nový Hunter?</Typography>
          <List>
            <ListItem disablePadding>
              <DoneIcon height="16px" /> Vstup do uzavřené sítě Legi.one
            </ListItem>
            <ListItem disablePadding>
              <DoneIcon height="16px" /> Přístup k více než 10 hodinám školení
            </ListItem>
            <ListItem disablePadding>
              <DoneIcon height="16px" /> Osobního mentora pro prvních 10
              obchodníků
            </ListItem>
            <ListItem disablePadding>
              <DoneIcon height="16px" /> Možnost vyzkoušet testovací licenci za
              1 Kč
            </ListItem>
            <ListItem disablePadding>
              <DoneIcon height="16px" /> Přístup na náš Discord a komunitní
              podporu
            </ListItem>
          </List>
        </Box>
        <Typography>
          Licencí je omezený počet – 998 v ČR, 338 na Slovensku. Každý Hunter si
          může portfolio obchodníků udržet, rozšířit, nebo prodat.
        </Typography>
      </Stack>
    </PrimaryPaper>
  </Stack>
);
