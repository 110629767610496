import { Box, Typography } from "@mui/material";
import type { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

type Props = {
  title?: string;
  goBackUrl?: string;
  onChevronClick?: () => void;
  children?: ReactNode;
};

const ChevronWrapper = styled.div`
  cursor: pointer;
  margin-right: ${(props) => props.theme.spacing(2)};

  svg {
    height: 1.4em;
    width: 1.4em;
    color: ${(props) => props.theme.palette.primary.dark};
  }
`;

export const PageTitle = ({
  title,
  goBackUrl,
  onChevronClick,
  children,
}: Props) => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      justifyItems="flex-start"
      alignItems={children ? "center" : "baseline"}
      className={goBackUrl ? "go-back-url" : "no-go-back-url"}
    >
      {(!!goBackUrl || onChevronClick) && (
        <ChevronWrapper
          className="chevron-wrapper"
          onClick={() =>
            onChevronClick
              ? onChevronClick()
              : !!goBackUrl && navigate(goBackUrl)
          }
        >
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-tzssek-MuiSvgIcon-root"
            viewBox="8 6 7.41 12"
          >
            <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
          </svg>
        </ChevronWrapper>
      )}
      {!!title && <Typography variant="h3">{title}</Typography>}
      {children}
    </Box>
  );
};
