import type { AddressType } from "Components/Onboarding/types";
import { z } from "zod";

export const addressValidationSchema: z.ZodType<AddressType> = z.object({
  addressID: z.number().optional(),
  street: z.string().min(1),
  descriptionNumber: z.string().min(1),
  orientationNumber: z.string().nullish(),
  city: z.string().min(1),
  postalCode: z.string().nullish(),
});

export const businessAddressValidationSchema: z.ZodType<AddressType> = z.object(
  {
    addressID: z.number().optional(),
    street: z.string().nullish(),
    descriptionNumber: z.string().nullish(),
    orientationNumber: z.string().nullish(),
    city: z.string().nullish(),
    postalCode: z.string().nullish(),
  },
);
