import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { useTransactionInvoiceSend } from "Api/Mutations/Transactions/useTransactionInvoiceSend";
import { useTransactionsStatus } from "Api/Queries/Transactions/useTransactionsStatus";
import { Colors } from "Components/Layout/Themes/Colors";
import { BlDivider } from "Components/Shared/BlDivider";
import { BlTextButton } from "Components/Shared/Buttons/BlTextButton";
import { DoneIcon, RiskIcon } from "Components/Shared/Icons";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { ConfirmBox } from "Components/Transactions/Anonymous/ConfirmBox";
import { EmailForm } from "Components/Transactions/Anonymous/EmailForm";
import { TransactionStatus2 } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import type { FC } from "react";

type Props = {
  transactionPublicID: string;
};

const ComponentResources = Resources.Transactions;

export const TransactionDetail: FC<Props> = ({ transactionPublicID }) => {
  const { t } = useResource();
  const { mutate, isPending, isSuccess } = useTransactionInvoiceSend();
  const { data: status, isLoading: isLoadingStatus } =
    useTransactionsStatus(transactionPublicID);

  const onReceiptSent = async (email: string) => {
    await mutate({ transactionPublicID, email });
  };

  const onReceiptDownload = () => {};

  const isFailed = status?.transactionStatus === TransactionStatus2.Fail;

  return (
    <PrimaryPaper>
      {isSuccess ? (
        <ConfirmBox />
      ) : (
        <>
          <Box display="flex" flexDirection="column" alignItems="center">
            {isFailed ? <RiskIcon color={Colors.Red} /> : <DoneIcon />}

            {isFailed ? (
              <>
                <Typography variant="h5" marginY={1} color="error">
                  {t(ComponentResources.ErrorPayment.Title)}
                </Typography>
                <Typography lineHeight="28px" textAlign="center" color="error">
                  {t(ComponentResources.ErrorPayment.Description)}
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="h5" marginY={1}>
                  {isLoadingStatus ? (
                    <Skeleton variant="text" width={150} />
                  ) : (
                    t(ComponentResources.SuccessPayment.Title)
                  )}
                </Typography>
                <Typography lineHeight="28px" textAlign="center">
                  {isLoadingStatus ? (
                    <>
                      <Skeleton variant="text" width={300} />
                      <Skeleton variant="text" width={300} />
                    </>
                  ) : (
                    <>
                      {t(
                        ComponentResources.SuccessPayment.DescriptionFirstPart,
                      )}
                      <br />
                      {t(
                        ComponentResources.SuccessPayment.DescriptionSecondPart,
                      )}
                    </>
                  )}
                </Typography>
              </>
            )}
          </Box>
          {!isFailed && (
            <>
              <BlDivider />
              {isLoadingStatus ? (
                <Stack gap={2}>
                  <Skeleton variant="rectangular" height={50} />
                  <Skeleton variant="rectangular" height={50} />
                  <Skeleton variant="rectangular" height={50} />
                </Stack>
              ) : (
                <>
                  <EmailForm onSubmit={onReceiptSent} isPending={isPending} />
                  <BlDivider text="nebo" />
                  <Box display="flex" justifyContent="center">
                    <BlTextButton onClick={onReceiptDownload}>
                      {t(ComponentResources.Anonymous.DownloadReceipt)}
                    </BlTextButton>
                  </Box>
                </>
              )}
            </>
          )}
        </>
      )}
    </PrimaryPaper>
  );
};
