import { OnboardingLayout } from "Components/Layout/OnboardingLayout";
import {
  OnboardingSteps,
  useOnboardingContext,
} from "Components/Onboarding/NaturalPerson/Context/OnboardingContext";
import { DocumentsStep } from "Components/Onboarding/NaturalPerson/Steps/DocumentsStep";
import { FinalStep } from "Components/Onboarding/NaturalPerson/Steps/FinalStep";
import { PersonalInformationStep } from "Components/Onboarding/NaturalPerson/Steps/PersonalInformationStep";
import { Resources, useResource } from "Translations/Resources";
import type { FC } from "react";

const StepsSelector: FC = () => {
  const { step } = useOnboardingContext();

  if (step === OnboardingSteps.PersonalDocument) {
    return <DocumentsStep />;
  }
  if (step === OnboardingSteps.UserDetail) {
    return <PersonalInformationStep />;
  }
  if (step === OnboardingSteps.LastStep) {
    return <FinalStep />;
  }

  return null;
};

export const NaturalPersonOnboarding: FC = () => {
  const { t } = useResource();
  const { hasPrevStep, prevStep } = useOnboardingContext();

  return (
    <OnboardingLayout
      title={t(Resources.Onboarding.ProcessTitle)}
      onChevronClick={hasPrevStep ? prevStep : undefined}
    >
      <StepsSelector />
    </OnboardingLayout>
  );
};
