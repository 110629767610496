import { PersonalInformation } from "Components/Onboarding/Components/PersonalInformation/PersonalInformation";
import {
  OnboardingSteps,
  useOnboardingContext,
} from "Components/Onboarding/NaturalPerson/Context/OnboardingContext";
import type { UserDetailModel } from "Components/Onboarding/types";
import type { FC } from "react";

export const PersonalInformationStep: FC = () => {
  const { getFormValues, nextStep, setFormValues } = useOnboardingContext();

  const onSave = (values: UserDetailModel) => {
    setFormValues(OnboardingSteps.UserDetail, values);
    nextStep();
  };

  return (
    <PersonalInformation
      onSave={onSave}
      initialValues={getFormValues(OnboardingSteps.UserDetail) ?? {}}
    />
  );
};
