import type { AddressType as AddressTypeModel } from "Components/Onboarding/types";
import { type AddressDto, AddressType } from "Infrastructure/Api/Api";
import { isValid } from "date-fns";

export const getAddressDtofromAddressType = (
  address: AddressTypeModel,
): AddressDto => {
  return {
    addressID: address.addressID ?? null,
    type: AddressType.Permanent,
    street: address.street ?? null,
    streetNumber: address.descriptionNumber ?? null,
    orientationNumber: address.orientationNumber ?? null,
    postalCode: address.postalCode,
    municipality: address.city,
  };
};

export const getAddressTypeFromAddressDto = (
  addressDto: AddressDto | undefined,
): Partial<AddressTypeModel> => {
  return {
    addressID: addressDto?.addressID ?? undefined,
    street: addressDto?.street ?? undefined,
    descriptionNumber: addressDto?.streetNumber ?? undefined,
    orientationNumber: addressDto?.orientationNumber ?? undefined,
    postalCode: addressDto?.postalCode ?? undefined,
    city: addressDto?.municipality ?? undefined,
  };
};

export const dateValueTransformer = (value?: string) => {
  if (!value) {
    return null;
  }

  const date = new Date(value);

  return isValid(date) ? date : null;
};
