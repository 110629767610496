import { useMutation } from "@tanstack/react-query";
import { getPartyQueryKey } from "Api/Queries/Party/usePartyQuery";
import { queryClient } from "App";
import {
  type GetPartiesPublicIDFetchResponse,
  type PostPartiesPublicIDUploadDocumentsFetchResponse,
  UploadIdentificationCommandResultStatus2,
  apiPost,
  getApiUrl,
  getPartiesPublicID,
  postPartiesPublicIDUploadDocumentsPath,
} from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  onSuccess?: (data: GetPartiesPublicIDFetchResponse) => void;
  onError?: (error: Error) => void;
};

type DocumentsType = {
  firstIDCardFrontSide: File;
  firstIDCardBackSide: File;
  secondIDCardFrontSide?: File;
};

const ApiResources = Resources.ApiStatuses;

export const useOCR = ({ onSuccess, onError }: Props = {}) => {
  const { t } = useResource();

  return useMutation({
    mutationFn: async ({
      documents,
      partyPublicID,
    }: {
      documents: DocumentsType;
      partyPublicID: string;
    }) => {
      const form = new FormData();

      form.append("FirstIDCardFrontSide", documents.firstIDCardFrontSide);
      form.append("FirstIDCardBackSide", documents.firstIDCardBackSide);

      if (documents.secondIDCardFrontSide) {
        form.append("SecondIDCardFrontSide", documents.secondIDCardFrontSide);
      }

      const result = await (apiPost(
        `${getApiUrl()}${postPartiesPublicIDUploadDocumentsPath(partyPublicID)}`,
        form,
        new Headers(),
      ) as Promise<PostPartiesPublicIDUploadDocumentsFetchResponse>);

      queryClient.invalidateQueries({
        queryKey: getPartyQueryKey(partyPublicID),
      });

      if (
        result.data?.status !== UploadIdentificationCommandResultStatus2.Success
      ) {
        throw new Error(
          result.data?.status
            ? t(
                ApiResources.UploadIdentificationCommandResultStatus2[
                  result.data?.status
                ],
              )
            : t(ApiResources.UnknownError),
        );
      }

      return await getPartiesPublicID(partyPublicID);
    },
    onSuccess: (data: GetPartiesPublicIDFetchResponse) => {
      onSuccess?.(data);
    },
    onError,
  });
};
