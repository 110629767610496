import { Dialog } from "@mui/material";
import styled from "styled-components";

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 90%;
    max-width: 550px;
    border-radius: 10px;
    padding: ${(props) => props.theme.spacing(6, 4)};
    box-shadow: none;
    border: 1px solid ${(props) => props.theme.colors.border};
    text-transform: uppercase;
  }

  .MuiDialogTitle-root {
    padding: 0;
    padding-bottom: ${(props) => props.theme.spacing(3)};
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }

  .MuiDialogContent-root {
    padding: 0;
  }

  .MuiDialogActions-root {
    padding: 0;
    padding-top: ${(props) => props.theme.spacing(2)};

    & > * {
      margin: 0;
      margin-right: ${(props) => props.theme.spacing(1.5)};

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;
