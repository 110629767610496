import { Stack } from "@mui/material";
import { SignUpForm } from "Components/Auth/SignUp/SignUpForm";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";

import type { FormData } from "Components/Auth/SignUp/SignUpForm";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import { PartyType } from "Infrastructure/Api/Api";
import { resetError, resetUser } from "State/Auth/AuthReducer";
import { signUpAsync } from "State/Auth/SignUp/SignUpState";
import type { RootStateType } from "State/Store";
import { Resources, useServerError } from "Translations/Resources";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

export const SignUpFormSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useLoggedInUser();
  const [searchParams] = useSearchParams();
  const { errorCode, isLoading } = useSelector((e: RootStateType) => e.auth);

  const { translateError } = useServerError(
    Resources.SignUp.Error,
    Resources.SignUp.Error.General,
  );

  useEffect(() => {
    dispatch(resetError());
  }, [dispatch]);

  const affiliateCode = searchParams.get("affiliateCode");

  useEffect(() => {
    if (affiliateCode) {
      localStorage.setItem("affiliateCode", affiliateCode);
    }
  }, [affiliateCode]);

  const email = searchParams.get("email");

  const onSubmit = ({ login, password }: FormData) => {
    //If there's a user logged in, sign him out before creating another one
    if (currentUser) {
      dispatch(resetUser({ isPageReloadDisabled: true }));
      if (affiliateCode) {
        localStorage.setItem("affiliateCode", affiliateCode);
      }
    }

    if (password) {
      dispatch(
        signUpAsync.request({
          model: {
            login,
            password,
            affiliateCode,
            partyType: PartyType.SelfEmployed,
            accessRoleCode: "Hunter",
          },
          navigate,
        }),
      );
    }
  };

  return (
    <Stack gap={3} mx={2}>
      <PrimaryPaper sx={{ width: "100%" }}>
        <SignUpForm
          key={email}
          isLoading={isLoading}
          onSubmit={onSubmit}
          serverError={translateError(errorCode)}
          onReset={() => {}} //Not used in the component anyways...
          defaultLogin={email}
        />
      </PrimaryPaper>
    </Stack>
  );
};
