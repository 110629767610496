import type { FormModel } from "Components/Merchant/Initiate/MerchantInitiateComponent";
import { Resources, useResource } from "Translations/Resources";
import { validateIBAN } from "ibantools";
import { z } from "zod";

const CommonResources = Resources.Common;

export const useFormValidationSchema = () => {
  const { t } = useResource();

  const InitiateMerchantSchema: z.ZodType<FormModel> = z
    .discriminatedUnion("accountType", [
      z.object({
        accountType: z.literal("iban"),
        publicID: z.string(),
        amount: z.number(),
        iban: z.string().max(34),
        bankAccountID: z.number().optional(),
      }),
      z.object({
        accountType: z.literal("bank"),
        publicID: z.string(),
        amount: z.number(),
        bankAccountID: z.number(),
        iban: z.string().optional(),
      }),
    ])
    .superRefine((data, ctx) => {
      if (data.accountType === "iban") {
        if (!data.iban || data.iban.trim() === "") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["iban"],
            message: t(CommonResources.Required),
          });
          return;
        }

        const ibanValue = data.iban.replace(/\s/g, "");

        // Validate IBAN format
        if (!validateIBAN(ibanValue).valid) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["iban"],
            message: t(CommonResources.InvalidIBAN),
          });
        }
      }
    });

  return InitiateMerchantSchema;
};
