import { Box } from "@mui/material";
import { Card } from "Components/BankAccounts/OrderEdit/Card";
import type { BankAccountListItem } from "Infrastructure/Api/Api";
import { type FC, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";

type Props = {
  items: BankAccountListItem[];
  handleChange: (items: BankAccountListItem[]) => void;
};

export const OrderEdit: FC<Props> = ({ items, handleChange }) => {
  const [isTouch] = useState(function isTouchDevice() {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0;
  });

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const dragCard = items[dragIndex];
    const newItems = [...items];
    newItems.splice(dragIndex, 1);
    newItems.splice(hoverIndex, 0, dragCard);
    handleChange(newItems);
  };

  const renderCard = (card: BankAccountListItem, index: number) => {
    return (
      <Card
        key={card.bankAccountID}
        index={index}
        moveCard={moveCard}
        bankAccount={card}
        isTouch={isTouch}
      />
    );
  };

  return (
    <DndProvider backend={isTouch ? TouchBackend : HTML5Backend}>
      <Box display="flex" flexDirection="column" gap={2}>
        {items.map(renderCard)}
      </Box>
    </DndProvider>
  );
};
