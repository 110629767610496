import type { UploadIdentificationCommandResultStatus } from "Api/Api";
import type {
  AdditionalInfoStatus,
  AddressType,
  AverageTransactionValue,
  Bank,
  BankVerificationStatus,
  BusinessActivity,
  ExpectedMonthlyRevenue,
  IdentityVerificationStatus,
  LicensePaymentStatus,
  PartyType,
  PaymentInstructionStatus,
  RelationType,
  RiskProfileStatus,
  SourceOfIncome,
  TransactionStatus2,
  UploadIdentificationCommandResultStatus2,
  UserStatus,
} from "Infrastructure/Api/Api";
import i18next, { type ResourceLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import { type ObjPathProxy, createProxy, getPath } from "ts-object-path";

export interface ResourceDictionary extends ResourceLanguage {
  Common: {
    AppName: string;
    Amount: string;
    Note: string;
    MandatoryNoteDescription: string;
    Unknown: string;
    ShowAll: string;
    ShowMore: string;
    VariableSymbol: string;
    Required: string;
    InvalidIBAN: string;
    CancelEdit: string;
    Cancel: string;
    Confirm: string;
    NiceDay: string;
    Send: string;
    Continue: string;
    FillMissing: string;
    FirstName: string;
    LastName: string;
    Gender: string;
    PersonalNumber: string;
    BirthPlace: string;
    Nationality: string;
    PostalCode: string;
    Number: string;
    OrientationNumber: string;
    DescriptionNumber: string;
    City: string;
    Type: string;
    IdentityDocument: string;
    Contact: string;
    Phone: string;
    Delete: string;
    Edit: string;
    BirthDate: string;
    AccountNumber: string;
    TransactionPaymentCode: string;
    IBAN: string;
  };
  CodeLists: {
    IdentificationList: {
      IDCard: string;
      Passport: string;
      Other: string;
    };
  };
  Errors: {
    RequestError: string;
    ApplicationError: {
      Title: string;
      Home: string;
    };
    Error404: {
      Subtitle: string;
      Home: string;
    };
    Error403: {
      Subtitle: string;
    };
  };
  SignIn: {
    Error: {
      General: string;
      Forbidden: string;
      InvalidLogin: string;
      InvalidCredentials: string;
      ApplicationVerification: string;
      PhoneNumberNotFound: string;
      SmsSendingFailed: string;
      InvalidToken: string;
      AccountNotFound: string;
      NotFound: string;
      AccountIsDeactivated: string;
    };
  };
  SignUp: {
    Error: {
      General: string;
      UserExists: string;
      WeakPassword: string;
    };
  };
  SetPassword: {
    Error: {
      General: string;
      TokenNotFound: string;
      WeakPassword: string;
    };
  };
  Validation: {
    Required: string;
    InvalidEmail: string;
  };
  Menu: {
    MainMenuItems: {
      Overview: string;
      InitiatePayment: string;
      History: string;
      Accounts: string;
      Licenses: string;
      Settings: string;
    };
    BottomMenuItems: {
      Overview: string;
      History: string;
      ActionButton: string;
      Accounts: string;
      Settings: string;
    };
  };
  Onboarding: {
    ProcessTitle: string;
    LicenseSelectionTitle: string;
    LicensePaymentTitle: string;
    ManualLicensePaymentTitle: string;
    AdditionalInformationTitle: string;
    BankAccountConnectionTitle: string;
    BankAccountVerificationTitle: string;
    PolicicallyExposed: {
      Title: string;
      Description: string;
      Alert: string;
    };
    Steps: {
      Documents: {
        LegalInformationBlock1: string;
        LegalInformationBlock2: string;
        Processing: string;
        ProcessingFailed: string;
        FirstDocumentTitle: string;
        SecondDocumentTitle: string;
        SecondDocumentDescription: string;
        Forms: {
          DocumentTitle: string;
          DocumentDescription: string;
          FrontSide: string;
          BackSide: string;
          SecondDocumentFrontSide: string;
        };
        Validations: {
          ForegroundMissing: string;
          BackgroundMissing: string;
          SecondForegroundMissing: string;
        };
      };
      PersonalInformation: {
        Title: string;
        DataConfirmation: string;
        FormGroups: {
          PersonalInformation: string;
          Address: string;
          IdentityDocument: string;
        };
        DocumentValidity: {
          Generic: string;
          From: string;
          To: string;
        };
        DocumentIssued: string;
        StreetAddress: string;
        ConfirmDataRequest: string;
      };
      FinalStep: {
        SubmitButton: string;
      };
    };
    BusinessOnboarding: {
      Steps: {
        OwnerModal: {
          Title: string;
        };
        AdditionalInformation: {
          Title: string;
          CheckboxTitle: string;
          CheckboxDescription: string;
          OwnersTitle: string;
          AddOwner: string;
          AddNextOwner: string;
        };
        AccountValidation: {
          Description: string;
          QRInstructions: string;
          PaymentNote: string;
        };
      };
    };
  };
  Licenses: {
    LicenseStatus: { [key in LicensePaymentStatus]: string };
  };
  Transactions: {
    Title: string;
    History: string;
    Payment: {
      Incoming: string;
      Outgoing: string;
      Unknown: string;
    };
    Type: {
      Buy: string;
      Sell: string;
      Withdrawal: string;
      LeadReward: string;
      LeadRewardConsumer: string;
      Voucher: {
        GiftCard: string;
        SignUpSubsidy: string;
      };
      Coinback: string;
    };
    ErrorPayment: {
      Title: string;
      Description: string;
    };
    SuccessPayment: {
      Title: string;
      DescriptionFirstPart: string;
      DescriptionSecondPart: string;
    };
    Anonymous: {
      DownloadReceipt: string;
      FillEmail: string;
    };
    Status: {
      Completed: string;
      PendingTrade: string;
      PendingPayout: string;
      WithdrawRequested: string;
      Withdrawn: string;
    };
    TransactionStatus: { [key in TransactionStatus2]: string };
    InstructionStatus: { [key in PaymentInstructionStatus]: string };
    InstructionStatusDescription: { [key in PaymentInstructionStatus]: string };
    BankTransferInfo: string;
  };
  BankIDVerification: {
    Error: {
      General: string;
      BankIDUserInfoError: string;
      VerificationAlreadyExists: string;
    };
  };
  Voucher: {
    ApplyError: {
      General: string;
      NotFound: string;
      Applied: string;
      UserAccountNotFound: string;
      UserNotFound: string;
      UserIsWaitingForEmailVerification: string;
      InvalidRecaptcha: string;
    };
  };
  Balance: {
    Title: string;
    BalanceContent: {
      Title: string;
      PayAction: string;
      SendPaymentAction: string;
    };
    NoAcount: {
      Title: string;
      Description: string;
      ActionButton: string;
    };
  };
  Banking: {
    Auth: {
      Title: string;
      Description: string;
      BankList: {
        Title: string;
        ComingSoon: string;
      };
    };
  };
  BankNames: { [key in Bank | "Unknown"]: string };
  Merchant: {
    Payment: {
      Title: string;
      InitiatePaymentButton: string;
    };
    Initiate: {
      GenerateButton: string;
      Form: {
        AccountSelectTitle: string;
      };
    };
    InitiateDetail: {
      Title: string;
      PaymentStatus: string;
      TransactionPaymentCode: string;
    };
  };
  Customer: {
    Pay: {
      Title: string;
      DescriptionText: string;
      PayButton: string;
      ChooseBankAccount: string;
      ChooseBank: string;
      AddNextBankAccount: string;
    };
  };
  BankAccounts: {
    List: {
      Description: string;
      NoAccounts: string;
      SaveOrder: string;
      EditOrder: string;
      AddAccount: string;
    };
  };
  Form: {
    PersonalData: {
      Sex: {
        Female: string;
        Male: string;
      };
    };
    PartyType: { [key in PartyType]: string };
    BusinessActivity: { [key in BusinessActivity]: string };
    SourceOfIncome: { [key in SourceOfIncome]: string };
    ExpectedMonthlyRevenue: { [key in ExpectedMonthlyRevenue]: string };
    AverageTransactionValue: { [key in AverageTransactionValue]: string };
    RelationType: { [key in RelationType]: string };
    BankVerification: { [key in BankVerificationStatus]: string };
    RiskProfile: { [key in RiskProfileStatus]: string };
    IdentityVerification: { [key in IdentityVerificationStatus]: string };
    AdditionalInfo: { [key in AdditionalInfoStatus]: string };
    UserStatus: { [key in UserStatus]: string };
    AddressType: { [key in AddressType]: string };
  };
  FormSelectors: {
    BusinessForm: {
      Title: string;
    };
  };

  ApiStatuses: {
    UnknownError: string;
    UploadIdentificationCommandResultStatus: {
      [key in UploadIdentificationCommandResultStatus]: string;
    };
    UploadIdentificationCommandResultStatus2: {
      [key in UploadIdentificationCommandResultStatus2]: string;
    };
  };
  zod: any;
}

export const Resources = createProxy<ResourceDictionary>();

export function getResourcePath(
  proxy: ObjPathProxy<ResourceDictionary, string>,
): string {
  const path = getPath(proxy);

  if (!path || path.length === 0) return "";
  if (path.length === 1) return path[0].toString();
  return `${path[0].toString()}:${path.slice(1).join(".")}`;
}

export function useResource() {
  const { t: i18Translation } = useTranslation();

  return {
    t: (
      resourcePath: ObjPathProxy<ResourceDictionary, string>,
      options?: any,
    ) =>
      i18Translation<string>(
        getResourcePath(resourcePath),
        options,
      ) as unknown as string,
  };
}

export function useServerError(
  parentObject: ObjPathProxy<ResourceDictionary, object>,
  fallbackProperty: ObjPathProxy<ResourceDictionary, string>,
) {
  const { t } = useResource();
  return {
    translateError: (code: string | null | undefined) => {
      if (!code) {
        return null;
      }

      const newCode = `${getResourcePath(parentObject as any)}.${code}`;

      const resource: string = i18next.exists(newCode)
        ? i18next.t(newCode)
        : (t(fallbackProperty) as unknown as string);

      return resource;
    },
  };
}
