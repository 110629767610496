import { Box, Paper, Typography } from "@mui/material";
import type { Ref } from "react";

type Props = { targetRef: Ref<HTMLDivElement> };

export const Video = ({ targetRef }: Props) => (
  <Box p={4} width="100%">
    <Paper
      ref={targetRef}
      square={false}
      elevation={24}
      sx={{
        textAlign: "center",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        p: 5,
      }}
    >
      <Box
        sx={{
          minWidth: "60%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "stretch",
          gap: 4,
        }}
      >
        <YouTubeEmbed videoId="DokAxxJYhpk" />
        <Typography px={3} fontSize="1rem">
          Za 6 minut Vám Patrik, zakladatel Legi.one, vysvětlí jak můžete
          vydělávat jako banka. Ne tolik, ale v principu. <br />
          Při každé transakci poplatek pro vás.
        </Typography>
      </Box>
    </Paper>
  </Box>
);

const YouTubeEmbed = ({ videoId }: { videoId: string }) => (
  <Box
    sx={{
      width: "100%",
      aspectRatio: "16/9",
      position: "relative",
    }}
  >
    <iframe
      width="100%"
      height="100%"
      src={`https://www.youtube.com/embed/${videoId}`}
      title="YouTube video vydělávej jako banka"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      style={{ borderRadius: "8px" }}
    />
  </Box>
);
