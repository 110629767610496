import { Box, CircularProgress, Typography } from "@mui/material";
import { ShoppingCartIcon } from "Components/Shared/Icons";
import { Resources, useResource } from "Translations/Resources";
import styled from "styled-components";

const ComponentResources = Resources.Banking.Auth.BankList;

const StyledIcon = styled.span<{
  $isDisabled?: boolean;
}>`
  padding: 0;
  width: 60px;
  height: 60px;
  border-radius: 0;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme, $isDisabled }) =>
    $isDisabled
      ? `
        color: ${theme.palette.primary.contrastText};
        background: ${theme.palette.action.disabledBackground};`
      : `
        color: ${theme.palette.common.white};
        background: ${theme.palette.primary.main};
  `}
`;

type Props = {
  isDisabled?: boolean;
  isLoading?: boolean;
};

export const IconSection = ({ isDisabled, isLoading }: Props) => {
  const { t } = useResource();

  return (
    <Box component="span" display="flex" alignItems="center" gap={5}>
      {isDisabled && (
        <Typography component="span">
          {t(ComponentResources.ComingSoon)}
        </Typography>
      )}
      <StyledIcon $isDisabled={isDisabled}>
        {isLoading ? (
          <CircularProgress size={18} color="inherit" />
        ) : (
          <ShoppingCartIcon width={28} height={50} />
        )}
      </StyledIcon>
    </Box>
  );
};
