import { Stack, Typography } from "@mui/material";
import {
  handleTransactionPayError,
  useTransactionPay,
} from "Api/Mutations/Transactions/useTransactionPay";
import { useBankingAccounts } from "Api/Queries/useBankingAccounts";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { BankingAccountSelector } from "Components/Shared/Inputs/Form/BankingAccountSelector";
import type { ErrorResponse } from "Infrastructure/Api/Api";
import { FORCE_BANK_ACCOUNT_SELECT_STEP } from "Pages/Customer/AnonymousCustomerPaymentPayPage";
import { Resources, useResource } from "Translations/Resources";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";

type FormModel = {
  bankAccountID: number;
};

const ComponentResources = Resources.Customer.Pay;

type BankAccountSelectorProps = {
  transactionPublicID: string;
  isAddingAccountDisabled?: boolean;
};

export const BankAccountSelector = ({
  transactionPublicID,
  isAddingAccountDisabled,
}: BankAccountSelectorProps) => {
  const { t } = useResource();
  const { data } = useBankingAccounts();
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormModel>();
  const navigate = useNavigate();
  const { mutateAsync: payTransaction, isPending } = useTransactionPay();

  const onSubmit = async ({ bankAccountID }: FormModel) => {
    try {
      await payTransaction({
        publicID: transactionPublicID,
        senderBankAccountID: bankAccountID,
      });
    } catch (e) {
      handleTransactionPayError(e as ErrorResponse);
    }
  };

  const onAddBankAccount = () => {
    navigate({ search: `?step=${FORCE_BANK_ACCOUNT_SELECT_STEP}` });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography marginBottom={4}>
        {t(ComponentResources.ChooseBankAccount)}
      </Typography>
      <BankingAccountSelector
        bankAccounts={data?.items ?? []}
        control={control}
        name="bankAccountID"
      />
      <Stack gap={3}>
        <PrimaryButton
          fullWidth
          color="primary"
          disabled={!isDirty}
          type="submit"
          isLoading={isPending}
        >
          {t(ComponentResources.PayButton)}
        </PrimaryButton>
        {!isAddingAccountDisabled && (
          <PrimaryButton onClick={onAddBankAccount}>
            {t(ComponentResources.AddNextBankAccount)}
          </PrimaryButton>
        )}
      </Stack>
    </form>
  );
};
