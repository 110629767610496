import { zodResolver } from "@hookform/resolvers/zod";
import { Grid } from "@mui/material";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { ValuesBox } from "Components/Onboarding/NaturalPerson/Components/ValuesBox";
import { useUserDetailValidationSchema } from "Components/Onboarding/NaturalPerson/Hooks/useUserDetailValidationSchema";
import type { UserDetailModel } from "Components/Onboarding/types";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { Dropdown } from "Components/Shared/Inputs/Form/Dropdown";
import { CountryDropdown } from "Components/Shared/Inputs/Form/Dropdowns/CountryDropdown";
import { IdentificationCardDropdown } from "Components/Shared/Inputs/Form/Dropdowns/IdentificationCardDropdown";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { useGenderList } from "Hooks/Options/useGenderList";
import { Resources, useResource } from "Translations/Resources";
import { PersonalIdentification } from "Utils/PersonalIdentification";
import { type FC, useEffect } from "react";
import { useForm } from "react-hook-form";

const StepResources = Resources.Onboarding.Steps.PersonalInformation;

type Props = {
  initialValues: Partial<UserDetailModel>;
  onSave: (values: UserDetailModel) => void;
};

export const EditValues: FC<Props> = ({ onSave, initialValues }) => {
  const { t } = useResource();
  const { userDetailValidationSchema } = useUserDetailValidationSchema();
  const { handleSubmit, control, watch, setValue } = useForm<UserDetailModel>({
    defaultValues: initialValues,
    resolver: zodResolver(userDetailValidationSchema),
  });
  const genderList = useGenderList();

  const onSubmit = (data: UserDetailModel) => {
    onSave(data);
  };

  const personalIdentificationNumber = watch("personalIdentificationNumber");

  useEffect(() => {
    if (personalIdentificationNumber) {
      const personalIdentificator = PersonalIdentification.getInfo(
        personalIdentificationNumber,
      );

      if (!personalIdentificator.IsValid) {
        return;
      }

      if (personalIdentificator.Gender) {
        setValue("gender", personalIdentificator.Gender);
      }
    }
  }, [personalIdentificationNumber, setValue]);

  return (
    <FullHeightForm onSubmit={handleSubmit(onSubmit)}>
      <StepBox submitButtonLabel="Uložit">
        <ValuesBox title="Základní údaje" hideDivider>
          <FormInput
            control={control}
            name="firstName"
            label={t(Resources.Common.FirstName)}
            required
          />
          <FormInput
            control={control}
            name="lastName"
            label={t(Resources.Common.LastName)}
            required
          />
          <FormInput
            control={control}
            name="personalIdentificationNumber"
            label={t(Resources.Common.PersonalNumber)}
            mask="000000/0000"
            required
          />
          <Dropdown
            codeList={genderList}
            control={control}
            name="gender"
            label={t(Resources.Common.Gender)}
          />
          <FormInput
            control={control}
            name="birthPlace"
            label={t(Resources.Common.BirthPlace)}
            required
          />
          <CountryDropdown
            control={control}
            name="country"
            label={t(Resources.Common.Nationality)}
          />
        </ValuesBox>
        <ValuesBox title="Adresa" hideDivider>
          <FormInput
            control={control}
            name="address.street"
            label={t(StepResources.StreetAddress)}
            required
          />
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormInput
                control={control}
                name="address.descriptionNumber"
                label={t(Resources.Common.DescriptionNumber)}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                control={control}
                name="address.orientationNumber"
                label={t(Resources.Common.OrientationNumber)}
              />
            </Grid>
          </Grid>
          <FormInput
            control={control}
            name="address.city"
            label={t(Resources.Common.City)}
            required
          />
          {/* PSC hidden for now*/}
          {/* <FormInput
            control={control}
            name="address.postalCode"
            label={t(Resources.Common.PostalCode)}
            mask="000 00"
            required
          /> */}
        </ValuesBox>
        <ValuesBox title={t(Resources.Common.IdentityDocument)} hideDivider>
          <IdentificationCardDropdown
            control={control}
            name="document.type"
            label={t(Resources.Common.Type)}
          />
          <FormInput
            control={control}
            name="document.number"
            label={t(Resources.Common.Number)}
            mask={/^[0-9]*$/}
          />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormInput
                control={control}
                name="document.valid.from"
                label={t(StepResources.DocumentValidity.From)}
                type="date"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInput
                control={control}
                name="document.valid.to"
                label={t(StepResources.DocumentValidity.To)}
                type="date"
                required
              />
            </Grid>
          </Grid>
          {/* Hidden for now*/}
          {/* <FormInput
            control={control}
            name="document.issued"
            label={t(StepResources.DocumentIssued)}
            required
          /> */}
        </ValuesBox>
      </StepBox>
    </FullHeightForm>
  );
};
