import { Box, Skeleton } from "@mui/material";
import { useBanksEnvironment } from "Api/Queries/Banks/useBanksEnvironments";
import { BankTile } from "Components/Shared/BankTile";
import { PaperTitle } from "Components/Shared/PaperTitle";
import type { BankEnvironment } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import { isSandbox } from "Utils/BankUtils";
import type { FC } from "react";

const PageResources = Resources.Banking.Auth.BankList;

type Props = {
  onBankSelected: (env: BankEnvironment) => void;
  isActionPending: boolean;
  selectedEnvironment?: BankEnvironment;
};

export const BankSelectorComponent: FC<Props> = ({
  onBankSelected,
  isActionPending,
  selectedEnvironment,
}) => {
  const { data, isLoading } = useBanksEnvironment();

  const { t } = useResource();

  const handleAuthorize = (env: BankEnvironment) => () => {
    onBankSelected(env);
  };

  return (
    <div>
      <PaperTitle>{t(PageResources.Title)}</PaperTitle>
      <Box display="flex" flexDirection="column" gap={4} marginTop={2}>
        {isLoading ? (
          <BankTilesLoader />
        ) : (
          <>
            {data?.items
              ?.filter(({ isVisible }) => isVisible)
              ?.map(({ bank, name, isEnabled, bankEnvironment }) => (
                <BankTile
                  key={`${bank}-${bankEnvironment}`}
                  bank={bank}
                  isSandbox={isSandbox(name)}
                  onAuthorize={handleAuthorize(bankEnvironment)}
                  isLoading={
                    isActionPending && selectedEnvironment === bankEnvironment
                  }
                  disabled={!isEnabled}
                />
              ))}
          </>
        )}
      </Box>
    </div>
  );
};

const BankTilesLoader = () => {
  return (
    <>
      <Skeleton variant="rectangular" height={52} />
      <Skeleton variant="rectangular" height={52} />
      <Skeleton variant="rectangular" height={52} />
      <Skeleton variant="rectangular" height={52} />
      <Skeleton variant="rectangular" height={52} />
    </>
  );
};
