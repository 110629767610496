import { Box, Stack } from "@mui/material";
import { Contacts } from "Components/Auth/SignUp/Hunter/Contacts";
import { FAQ } from "Components/Auth/SignUp/Hunter/FAQ";
import { GetLicenseButton } from "Components/Auth/SignUp/Hunter/GetLicenseButton";
import { Headline } from "Components/Auth/SignUp/Hunter/Headline";
import { HunterRoleExplanation } from "Components/Auth/SignUp/Hunter/HunterRoleExplanation";
import { LegioneInfo } from "Components/Auth/SignUp/Hunter/LegioneInfo";
import { MooneInfoDark } from "Components/Auth/SignUp/Hunter/MooneInfoDark";
import { MoreInfoButton } from "Components/Auth/SignUp/Hunter/MoreInfoButton";
import { Partners } from "Components/Auth/SignUp/Hunter/Partners";
import { SignUpFormSection } from "Components/Auth/SignUp/Hunter/SignUpFormSection";
import { Video } from "Components/Auth/SignUp/Hunter/Video";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { useRef } from "react";

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const HunterSignUpPage = () => {
  const videoContainerRef = useRef<HTMLDivElement>(null);

  const scrollToVideo = () => {
    videoContainerRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <UnauthenticatedLayout title="Staňte se Hunterem">
      <Stack alignItems="center" gap={8} pb={4}>
        <Stack minHeight="100vh" justifyContent="space-between" gap={4}>
          <Box height="100%" display="flex" flexGrow={1}>
            <Stack gap={4} maxWidth="570px" alignSelf="center">
              <Headline />
              <SignUpFormSection />
            </Stack>
          </Box>
          <Box mb={4} width="100%" display="flex" justifyContent="center">
            <MoreInfoButton onClick={scrollToVideo} />
          </Box>
        </Stack>
        <Video targetRef={videoContainerRef} />
        <HunterRoleExplanation />
        <Partners />
        <MooneInfoDark />
        <FAQ />
        <LegioneInfo />
        <GetLicenseButton onClick={scrollToTop} />
        <Contacts />
      </Stack>
    </UnauthenticatedLayout>
  );
};

export default HunterSignUpPage;
