import { useMutation } from "@tanstack/react-query";
import { postTransactionsInvoiceSend } from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

export const useTransactionInvoiceSend = () =>
  useMutation({
    mutationFn: processResponseWithApiProblemDetails(
      postTransactionsInvoiceSend,
    ),
  });
