import { DialogTitle, type DialogTitleProps, Stack } from "@mui/material";
import { UnstyledButton } from "Components/Shared/Buttons/UnstyledButton";
import { CloseIcon } from "Components/Shared/Icons";

type Props = DialogTitleProps & {
  onClose?: () => void;
};

export const BlDialogTitle: React.FC<Props> = ({
  onClose,
  children,
  ...props
}) => {
  if (!onClose) {
    return <DialogTitle {...props}>{children}</DialogTitle>;
  }

  return (
    <DialogTitle {...props}>
      <Stack direction="row" alignItems="start" justifyContent="space-between">
        <div>{children}</div>
        <UnstyledButton
          sx={{ cursor: "pointer", ml: 2, pt: "1px" }}
          onClick={onClose}
        >
          <CloseIcon width={18} height="auto" />
        </UnstyledButton>
      </Stack>
    </DialogTitle>
  );
};
