import { useQuery } from "@tanstack/react-query";
import { getPartiesPublicID } from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

export const PARTY_QUERY_KEY = ["PARTY"];

export const getPartyQueryKey = (publicID: string) => [
  PARTY_QUERY_KEY,
  { publicID },
];

export const usePartyQuery = (publicID?: string) =>
  useQuery({
    queryKey: getPartyQueryKey(publicID!),
    queryFn: processResponseWithApiProblemDetails(() =>
      getPartiesPublicID(publicID!),
    ),
    enabled: !!publicID,
    refetchOnWindowFocus: false,
  });
