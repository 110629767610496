import { Skeleton, Stack } from "@mui/material";
import { useBankingAccounts } from "Api/Queries/useBankingAccounts";
import { BankAccountSelector } from "Components/AnonymousCustomer/Payment/BankAccountSelector";
import { BankSelector } from "Components/AnonymousCustomer/Payment/BankSelector";
import type { FC } from "react";

type Props = {
  transactionPublicID: string;
  isAccountSelect: boolean;
  forceBankSelector?: boolean;
};

export const BankPaymentComponent: FC<Props> = ({
  transactionPublicID,
  isAccountSelect,
  forceBankSelector,
}) => {
  const { data, isLoading } = useBankingAccounts();

  if (isLoading) {
    return (
      <Stack gap={3}>
        <Skeleton variant="rectangular" height={60} />
        <Skeleton variant="rectangular" height={60} />
        <Skeleton variant="rectangular" height={60} />
        <Skeleton variant="rectangular" height={60} />
        <Skeleton variant="rectangular" height={60} />
      </Stack>
    );
  }

  return !forceBankSelector && (isAccountSelect || data?.items?.length) ? (
    <BankAccountSelector transactionPublicID={transactionPublicID} />
  ) : (
    <BankSelector transactionPublicID={transactionPublicID} />
  );
};
