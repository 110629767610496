import { Stack, Typography } from "@mui/material";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";

export const LegioneInfo = () => (
  <Stack gap={3} sx={{ width: "70vw" }}>
    <Typography variant="h2" mb={3}>
      Proč vzniklo Legi.one?
    </Typography>
    <PrimaryPaper>
      <Typography>
        Každý den proběhnou v Česku bezhotovostní transakce za více než 4,5
        miliardy Kč. Na poplatcích se ročně vydělá přes 25 miliard Kč – většinu
        z toho inkasují zahraniční technologické firmy a banky.
      </Typography>
      <Typography my={4}>My to měníme.</Typography>
      <Typography>
        S produktem Mo.one zavádíme nový způsob přijímání plateb – přímou platbu
        bez prostředníků jako Visa nebo Mastercard.
      </Typography>
      <Typography>
        Obchodník dostává peníze ihned. Ty jako Hunter jsi u toho – pomáháš,
        vzděláváš, stavíš vztah.
      </Typography>
    </PrimaryPaper>
  </Stack>
);
