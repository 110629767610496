import { Box, Card, Typography } from "@mui/material";
import { SandboxText } from "Components/Shared/Bank/SandboxText";
import { BankImage } from "Components/Shared/BankImage";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PlusMathIcon } from "Components/Shared/Icons";
import type { Bank } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import styled, { css } from "styled-components";

const StyledCard = styled(Card)<{
  $border?: "active" | "inactive";
  $clickable?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  box-shadow: none;
  border-radius: 10px;

  ${({ $clickable }) =>
    $clickable &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.palette.grey[100]};
      }
    `}

  ${({ theme, $border }) =>
    $border &&
    css`
      border-color: ${
        $border === "active"
          ? theme.palette.primary.main
          : theme.palette.grey[300]
      } !important;

      border: ${$border === "active" ? "2px" : "1px"} solid;
      border-radius: 2;
    `}
`;

const StyledButton = styled(PrimaryButton)`
  padding: 0;
  min-width: 60px;
  width: 60px;
  border-radius: 0;
  box-shadow: none;

  &.Mui-disabled {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background: none;
    background-color: ${({ theme }) => theme.palette.grey[500]};
  }
`;

type Props = {
  disabled?: boolean;
  bank: Bank | "Unknown";
  customName?: string;
  isSandbox?: boolean;
  onAuthorize?: () => void;
  isLoading?: boolean;
  border?: "active" | "inactive";
  clickable?: boolean;
};

const ComponentResources = Resources.Banking.Auth.BankList;
const BankNameResources = Resources.BankNames;

export const BankTile = ({
  disabled,
  bank,
  isSandbox,
  customName,
  onAuthorize,
  isLoading,
  border,
  clickable,
}: Props) => {
  const { t } = useResource();

  const bankName = t(BankNameResources[bank]);
  const name = customName || bankName;

  return (
    <StyledCard $border={border} $clickable={clickable}>
      <Box display="flex" alignItems="center" marginLeft={2} paddingY={1.5}>
        <Box marginRight={5} lineHeight={0}>
          <BankImage bank={bank} name={name} />
        </Box>
        <span>{name}</span>

        {isSandbox && <SandboxText />}
      </Box>
      <Box display="flex" alignItems="center" gap={5}>
        {disabled && (
          <Typography>{t(ComponentResources.ComingSoon)}</Typography>
        )}
        {!!onAuthorize && (
          <StyledButton
            color="primary"
            disabled={disabled}
            onClick={onAuthorize}
            isLoading={isLoading}
          >
            <PlusMathIcon width={28} />
          </StyledButton>
        )}
      </Box>
    </StyledCard>
  );
};
