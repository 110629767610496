import { Box, Grid, Typography } from "@mui/material";
import { useBankingAccountsOrder } from "Api/Mutations/Accounts/useBankingAccountsOrder";
import { useBankingAccounts } from "Api/Queries/useBankingAccounts";
import { BankAccountRowLink } from "Components/BankAccounts/BankAccountRowLink";
import { OrderEdit } from "Components/BankAccounts/OrderEdit/OrderEdit";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { useNavigationWithPreviousPage } from "Hooks/useNavigateBackManager";
import type { BankAccountListItem } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import { getAppUrl } from "Utils/UrlUtils";
import { useState } from "react";
import { useNavigate } from "react-router";

const PageResources = Resources.BankAccounts.List;

export const BankAccountListPage = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [items, setItems] = useState<BankAccountListItem[]>([]);

  const { data: bankingAccounts, isLoading } = useBankingAccounts();
  const { mutateAsync: saveOrder, isPending } = useBankingAccountsOrder();

  const { previousPageUrl } = useNavigationWithPreviousPage();
  const { t } = useResource();
  const navigate = useNavigate();

  const appUrl = getAppUrl();
  const handleEdit = () => {
    if (!isEdit) {
      setItems(bankingAccounts?.items ?? []);
    }

    setIsEdit((p) => !p);
  };

  const handleSaveOrder = async () => {
    await saveOrder(items);
    setIsEdit(false);
  };

  const hasMultipleAccounts = (bankingAccounts?.items.length ?? 0) > 1;

  return (
    <AuthenticatedLayout title="Bankovní účty" goBackUrl={previousPageUrl}>
      <StyledPageWrapper>
        <div className="left-content">
          {!isLoading && !!bankingAccounts && (
            <div>
              <PrimaryPaper>
                <Typography fontSize={16}>
                  {t(PageResources.Description)}
                </Typography>

                {bankingAccounts.items.length > 0 && (
                  <div>
                    <br />
                    {isEdit ? (
                      <OrderEdit items={items} handleChange={setItems} />
                    ) : (
                      <Box display="flex" flexDirection="column" gap={2}>
                        {bankingAccounts.items.map((e) => (
                          <BankAccountRowLink key={e.bankAccountID} item={e} />
                        ))}
                      </Box>
                    )}

                    <br />
                  </div>
                )}

                {!bankingAccounts.items.length && (
                  <Typography
                    marginTop={6}
                    marginBottom={6}
                    fontSize={16}
                    fontWeight={600}
                  >
                    {t(PageResources.NoAccounts)}
                  </Typography>
                )}

                <Grid container spacing={4} marginTop={2}>
                  <Grid item xs={12} md={hasMultipleAccounts ? 7 : 12}>
                    {isEdit ? (
                      <PrimaryButton
                        color="info"
                        onClick={handleSaveOrder}
                        fullWidth
                        isLoading={isPending}
                      >
                        {t(PageResources.SaveOrder)}
                      </PrimaryButton>
                    ) : (
                      <PrimaryButton
                        color="info"
                        onClick={() => navigate(appUrl("banking-auth"))}
                        fullWidth
                      >
                        {t(PageResources.AddAccount)}
                      </PrimaryButton>
                    )}
                  </Grid>
                  {hasMultipleAccounts && (
                    <Grid item xs={12} md={5}>
                      <PrimaryButton
                        color="info"
                        fullWidth
                        variant="outlined"
                        onClick={handleEdit}
                        isLoading={isPending}
                        disabled={!bankingAccounts?.items.length}
                      >
                        {isEdit
                          ? t(Resources.Common.CancelEdit)
                          : t(PageResources.EditOrder)}
                      </PrimaryButton>
                    </Grid>
                  )}
                </Grid>
              </PrimaryPaper>
            </div>
          )}
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default BankAccountListPage;
