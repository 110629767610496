import { AnonymousLayout } from "Components/Layout/AnonymousLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { TransactionDetail } from "Components/Transactions/Anonymous/TransactionDetail";
import { appUrl } from "Utils/UrlUtils";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const AnonymousTransactionDetailPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = (_) => {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      // this should never happen, but just in case
      navigate(appUrl("transactions"));
    }
  }, [id, navigate]);

  return (
    <AnonymousLayout title="Transakce byla úspěšná">
      <StyledPageWrapper>
        <div className="left-content">
          {id && <TransactionDetail transactionPublicID={id} />}
        </div>
      </StyledPageWrapper>
    </AnonymousLayout>
  );
};
