import { Box, Button } from "@mui/material";
import { ArrowRightIcon } from "Components/Shared/Icons";

type Props = { onClick: () => void };

export const MoreInfoButton = ({ onClick }: Props) => (
  <Button
    onClick={onClick}
    variant="outlined"
    size="large"
    sx={{
      alignSelf: "center",
      textTransform: "none",
      borderRadius: "20px",
      fontWeight: 300,
    }}
  >
    <Box display="flex" gap={2} alignItems="center">
      Více informací
      <Box sx={{ transform: "rotate(90deg)" }}>
        <ArrowRightIcon width="12px" />
      </Box>
    </Box>
  </Button>
);
