import { Box, Button, Typography } from "@mui/material";
import type { PaymentActionsProps } from "Components/Dashboard/BalanceWithPaymentActions/types";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { ForwardArrowIcon, PlusMathIcon } from "Components/Shared/Icons";

export const PaymentActions = ({
  payMeAction,
  sendPaymentAction,
  isDisabled,
}: PaymentActionsProps) => (
  <Box display="flex" gap={4} mt={4}>
    <SendPaymentButton {...sendPaymentAction} isDisabled={isDisabled} />
    <PayMeButton {...payMeAction} isDisabled={isDisabled} />
  </Box>
);

type PaymentButtonProps = {
  isDisabled: boolean;
  link: string;
  text: string;
};

const SendPaymentButton = ({ isDisabled, link, text }: PaymentButtonProps) => (
  <PrimaryButton
    fullWidth
    type="button"
    color="primary"
    disabled={isDisabled}
    href={isDisabled ? undefined : link}
    startIcon={<PlusMathIcon width={20} height={20} />}
  >
    <Typography fontWeight={600} fontSize={14}>
      {text}
    </Typography>
  </PrimaryButton>
);

const PayMeButton = ({ isDisabled, link, text }: PaymentButtonProps) => (
  <Button
    fullWidth
    type="button"
    color="info"
    variant="outlined"
    disabled={isDisabled}
    href={isDisabled ? undefined : link}
    startIcon={<ForwardArrowIcon width={20} height={20} />}
  >
    <Typography fontWeight={600} fontSize={14}>
      {text}
    </Typography>
  </Button>
);
