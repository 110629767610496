import { Box, Typography } from "@mui/material";
import { SandboxText } from "Components/Shared/Bank/SandboxText";
import { BankImage } from "Components/Shared/BankImage";
import type { Bank } from "Infrastructure/Api/Api";

type Props = {
  bank: Bank | "Unknown";
  title: string;
  isDisabled?: boolean;
  isSandbox?: boolean;
};

export const ImageWithTitle = ({
  bank,
  title,
  isSandbox,
  isDisabled,
}: Props) => (
  <Box component="span" display="flex" ml={3} py={3}>
    <Box component="span" display="flex" alignItems="center" gap={3}>
      <BankImage bank={bank} name={title} isGrayscale={isDisabled} />
      <Typography component="span">
        {title} {isSandbox && <SandboxText />}
      </Typography>
    </Box>
  </Box>
);
