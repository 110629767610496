import { Box, Stack, Typography } from "@mui/material";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import type { ReactNode } from "react";

export const FAQ = () => (
  <Stack gap={3} sx={{ width: "70vw" }}>
    <Typography variant="h2" mb={3}>
      Nejčastější otázky (FAQ)
    </Typography>
    <QuestionWithAnswer
      question="Je to multilevel nebo síťový marketing?"
      answer="Ne. Každý Hunter podniká samostatně. Bez struktur, bez povinného náboru."
    />
    <QuestionWithAnswer
      question="Musím při registraci nahrát občanku?"
      answer="Ano, registrací do aplikace Mo.one se
stáváte zákazníkem regulované společnosti ZNPay a.s., která je ze zákona
povinna ověřit vaši totožnost."
    />
    <QuestionWithAnswer
      question="Musím mít zkušenosti?"
      answer="Nemusíš. Vše potřebné tě naučíme – krok za krokem."
    />
    <QuestionWithAnswer
      question="Kolik si mohu vydělat?"
      answer="Záleží jen na tvé aktivitě. Od několika tisíc měsíčně až po vlastní
        byznys, který lze prodat."
    />
    <QuestionWithAnswer
      question="Co když si to rozmyslím?"
      answer={
        <Stack gap={3}>
          <Typography>
            Registrace do Mo.one v odkazu nahoře je zdarma. Můžeš si vyzkoušet
            jak vypadá prostředí, do kterého se registrují i obchodníci.
            Testovací licence za 1 Kč umožňuje vyzkoušet přímou platbu.
          </Typography>
          <Typography>
            Pokud se rozhodneš zakoupit licenci, můžeš ji následně kdykoliv
            prodat.
          </Typography>
        </Stack>
      }
    />
  </Stack>
);

const QuestionWithAnswer = ({
  question,
  answer,
}: { question: string; answer: ReactNode }) => (
  <PrimaryPaper sx={{ width: "100%" }}>
    <Box>
      <Typography variant="h6">{question}</Typography>
      {answer}
    </Box>
  </PrimaryPaper>
);
