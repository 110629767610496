import { zodResolver } from "@hookform/resolvers/zod";
import { CircularProgress, Typography } from "@mui/material";
import { useOCR } from "Api/Mutations/Onboarding/useOCR";
import { usePartyQuery } from "Api/Queries/Party/usePartyQuery";
import { useVerificationsQuery } from "Api/Queries/Verifications/useVerificationsQuery";
import { IdentityCardForms } from "Components/Onboarding/Components/IdentityCardForms";
import { ProcessingOcr } from "Components/Onboarding/Components/ProcessingOcr";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import {
  OnboardingSteps,
  type PersonalDocumentModel,
  useOnboardingContext,
} from "Components/Onboarding/NaturalPerson/Context/OnboardingContext";
import { getUserDetailFromOCRResult } from "Components/Onboarding/NaturalPerson/Utils/ocrUtils";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { logError } from "ErrorService";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import { IdentityVerificationStatus } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const StepResources = Resources.Onboarding.Steps.Documents;

const ExistingDocumentsInfo = () => {
  const user = useLoggedInUser();
  const { data } = usePartyQuery(user?.partyPublicID);
  const { nextStep, setFormValues, setPartyData } = useOnboardingContext();
  const { t } = useResource();

  if (!data) {
    return null;
  }

  const handleSubmit = () => {
    setFormValues(OnboardingSteps.UserDetail, getUserDetailFromOCRResult(data));
    setPartyData(data);
    nextStep();
  };

  return (
    <StepBox
      title={t(StepResources.LegalInformationBlock1)}
      subTitle={t(StepResources.LegalInformationBlock2)}
      submitButtonLabel={t(Resources.Common.Continue)}
      withDivider
      onSubmitClick={handleSubmit}
    >
      <Typography fontWeight="bold" fontSize="15px">
        Vaše doklady jsou již nahrány a probíhá jejich zpracování. Můžete
        pokračovat dalším krokem.
      </Typography>
    </StepBox>
  );
};

export const DocumentsStep = () => {
  const { data, isLoading, isError } = useVerificationsQuery();
  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError || !data) {
    return <>Chyba, nepodařilo se načíst dokumenty</>;
  }

  const hasDocumentsUploaded =
    data.identityVerificationStatus === IdentityVerificationStatus.Processing;
  return hasDocumentsUploaded ? (
    <ExistingDocumentsInfo />
  ) : (
    <DocumentsStepContent />
  );
};

const DocumentsStepContent = () => {
  const { t } = useResource();
  const user = useLoggedInUser();

  const [validationSchema] = useState<z.ZodType<PersonalDocumentModel>>(
    z.object({
      frontSide: z.instanceof(File, {
        message: t(StepResources.Validations.ForegroundMissing),
      }),
      backSide: z.instanceof(File, {
        message: t(StepResources.Validations.BackgroundMissing),
      }),
    }),
  );

  const { mutateAsync: ocrRequest, isPending: isProcessingOcr } = useOCR({
    onError: () => {
      toast.error("Údaje z dokumentů se nepodařilo přečíst");
      nextStep();
    },
  });
  const { nextStep, setFormValues, setPartyData, getFormValues } =
    useOnboardingContext();
  const { control, handleSubmit } = useForm<PersonalDocumentModel>({
    resolver: zodResolver(validationSchema),
    defaultValues: getFormValues(OnboardingSteps.PersonalDocument),
  });

  const onSubmit = handleSubmit(async (data) => {
    if (isProcessingOcr) {
      return;
    }

    setFormValues(OnboardingSteps.PersonalDocument, data);

    const result = await ocrRequest({
      documents: {
        firstIDCardBackSide: data.backSide,
        firstIDCardFrontSide: data.frontSide,
      },
      // TODO: make partyPublicID required
      partyPublicID: user?.partyPublicID as string,
    });

    if (result) {
      if (!result.data?.publicID) {
        toast.error(t(StepResources.ProcessingFailed));
        logError(new Error("OCR failed - publicID missing"));
      }

      if (result.data) {
        setFormValues(
          OnboardingSteps.UserDetail,
          getUserDetailFromOCRResult(result.data),
        );

        setPartyData(result.data);
      }
      nextStep();
    }
  });

  if (isProcessingOcr) {
    return <ProcessingOcr />;
  }

  return (
    <FullHeightForm onSubmit={onSubmit}>
      <StepBox
        title={t(StepResources.LegalInformationBlock1)}
        subTitle={t(StepResources.LegalInformationBlock2)}
        submitButtonLabel={t(Resources.Common.Continue)}
        withDivider
      >
        <IdentityCardForms
          control={control}
          frontSideName="frontSide"
          backSideName="backSide"
        />
      </StepBox>
    </FullHeightForm>
  );
};
