import { Box, Stack, Typography } from "@mui/material";

export const Headline = () => (
  <Stack textAlign="center" justifyContent="center" gap={4} mx={1}>
    <Typography variant="h1">Staňte se Hunterem</Typography>
    <Box>
      <Typography fontSize="1.5rem" lineHeight="1.8rem" color="grey.500">
        Chceme nahradit drahé platby přes karty a umožnit obchodníkům vydělat
        víc peněz.
      </Typography>
      <Typography fontSize="1.5rem" color="grey.500" mt={1}>
        Proto potřebujeme vás.
      </Typography>
    </Box>
  </Stack>
);
