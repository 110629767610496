import { Stack, Typography, Box } from "@mui/material";

export const MooneInfoDark = () => (
  <Box
    p={6}
    width="100%"
    textAlign="center"
    bgcolor="grey.900"
    color="common.white"
  >
    <Stack px={10} gap={4}>
      <Typography>
        Staňte se součástí týmu, který desítky let formuje budoucnost plateb.
        Stáli jsme u zrodu technologií, díky kterým dnes vybíráte hotovost z
        bankomatů. V roce 2012 jsme představili první chytrý platební terminál
        na světě. A dnes? Patříme mezi hrstku firem v Česku, které od České
        národní banky získaly licenci PSD2 pro služby AIS a PIS.
      </Typography>
      <Typography>
        S Mo.one přichází nová éra. Je to víc než produkt. Je to způsob, jak
        platit lépe. S Legi.one tvoříme komunitu, která tuto změnu přinese
        všude.
      </Typography>
      <Stack mt={3} gap={1}>
        <Typography>
          Děláme vše pro to, aby na otázku
          <Typography component="em" mx={1}>
            „Hotově, nebo kartou?”
          </Typography>
          byla jediná odpověď:
        </Typography>
        <Typography variant="h6" mt={0}>
          „Jedině s Mo.one.”
        </Typography>
      </Stack>
    </Stack>
  </Box>
);
