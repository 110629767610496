import { yupResolver } from "@hookform/resolvers/yup";
import { Link, Stack, Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { CheckboxInput } from "Components/Shared/Inputs/Form/CheckboxInput";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { getApiUrl } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import {
  type ObjectSchema,
  type StringSchema,
  boolean,
  object,
  string,
} from "yup";

const StyledForm = styled.form`
  margin-top: ${(props) => props.theme.spacing(2)};
`;

const StyledSubmitButton = styled(PrimaryButton)`
  margin-top: ${(props) => props.theme.spacing(3)};
`;

const StyledServerError = styled(Typography)`
  color: ${(props) => props.theme.colors.red};
`;

export type FormData = {
  login: string;
  password?: string;
  agreeWithTermsAndConditions: boolean;
};

type Props = {
  isLoading: boolean;
  serverError: string | null;
  defaultLogin: string | null;
  isPasswordless?: boolean;
  onSubmit: (formData: FormData) => void;
  onReset: () => void;
};

export const SignUpForm = ({
  isLoading,
  serverError,
  defaultLogin,
  isPasswordless,
  onSubmit,
}: Props) => {
  const { t } = useResource();

  const validationSchema: ObjectSchema<FormData> = object({
    login: string()
      .email("E-mail není validní")
      .required(t(Resources.Validation.Required)),
    password: (isPasswordless
      ? string().notRequired()
      : string()
          .required(t(Resources.Validation.Required))
          .min(8, "Heslo musí mít alespoň 8 znaků")
          .matches(/[a-z]/, "Heslo musí obsahovat alespoň jedno malé písmeno")
          .matches(/[A-Z]/, "Heslo musí obsahovat alespoň jedno velké písmeno")
          .matches(
            /[0-9]/,
            "Heslo musí obsahovat alespoň jedno číslo",
          )) as StringSchema<string>,
    agreeWithTermsAndConditions: boolean()
      .required()
      .isTrue("Musíte souhlasit s obch. podmínkami"),
  }).defined();

  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      login: defaultLogin ?? "",
      password: "",
      agreeWithTermsAndConditions: false,
    },
  });

  useEffect(() => {
    if (serverError) {
      setError("login", {
        type: "required",
        message: "",
      });
    }
  }, [serverError, setError]);

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Stack justifyContent="center">
        <FormInput
          autoFocus
          control={control}
          name="login"
          errors={errors}
          label="E-mail"
          fullWidth={true}
          valueTransformer={(v) => v.toLowerCase()}
        />
        {!isPasswordless && (
          <FormInput
            control={control}
            name="password"
            errors={errors}
            label="Heslo"
            type="password"
            autoComplete="current-password"
            fullWidth={true}
          />
        )}
        <CheckboxInput
          control={control}
          name="agreeWithTermsAndConditions"
          errors={errors}
          label={
            <StyledFlex gap="5px">
              <Typography>
                Registrací do aplikace Mo.one uzavírám
                <Link
                  target="_blank"
                  href={`${getApiUrl()}/content/terms/2A-ZNPay-precontract-contract-info.pdf`}
                  mx={1}
                >
                  Smlouvu o poskytování platebních služeb
                </Link>
                se společností ZNPay a.s., jejíž součástí jsou tyto
                <Link
                  target="_blank"
                  href={`${getApiUrl()}/content/terms/2B-ZNPay-related-docs.pdf`}
                  ml={1}
                >
                  Související podmínky
                </Link>
                .
              </Typography>
            </StyledFlex>
          }
        />
        <StyledServerError variant="subtitle1">{serverError}</StyledServerError>
        <StyledSubmitButton
          type="submit"
          fullWidth
          variant="contained"
          color="info"
          isLoading={isLoading}
        >
          Vytvořit účet
        </StyledSubmitButton>
      </Stack>
    </StyledForm>
  );
};
